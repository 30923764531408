export class SectorModel {
    id: number;
    name: string;
}

export class SubSectorModel {
    id: number;
    name: string;
    sectorId: number;
}

export class SectorCreateRequest {
    constructor(public name: string) {
    }
}

export class SectorUpdateRequest {
    constructor(
        public id: number,
        public name: string
    ) {
    }
}

export class SubSectorCreateRequest {
    constructor(
        public name: string,
        public sectorId: number
    ) {
    }
}

export class SubSectorUpdateRequest {
    constructor(
        public id: number,
        public name: string,
        public sectorId: number
    ) {
    }
}
